
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPagePageLayout} from 'layouts/CmsPagePageLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPagePageLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p><strong parentName="p">{`Un sito web `}<em parentName="strong">{`statico`}</em>{` genera e ottimizza i suoi contenuti`}</strong>{` in fase di pubblicazione. Quando un visitatore raggiunge il sito, il server web gli può inviare tutti i contenuti nel minor tempo possibile perché essi sono generati anticipatamente.`}</p>
    <p>{`Un sito web `}<em parentName="p">{`dinamico`}</em>{`, al contrario, genera le sue pagine in fase di visualizzazione. In questo caso il server web, non disponendo di contenuti pronti, deve generarli recuperandoli da un database prima di poterli inviare.`}</p>
    <p>{`La differenza che si percepisce subito riguarda i tempi di attesa per un visitatore, che sono:`}</p>
    <ul>
      <li parentName="ul">{`predefiniti e ottimizzati per un sito web `}<em parentName="li">{`statico`}</em>{`;`}</li>
      <li parentName="ul">{`variabili per un sito web `}<em parentName="li">{`dinamico`}</em>{` a seconda di quanto scambio di informazioni c’è fra server web, generatore e database;`}</li>
    </ul>
    <p><strong parentName="p">{`I siti web statici vivono nel cloud`}</strong>{` e sono adatti sia a semplici `}<em parentName="p">{`siti vetrina`}</em>{`, sia a portali più complessi come `}<em parentName="p">{`blog`}</em>{` ed `}<em parentName="p">{`e-commerce`}</em>{`.`}</p>
    <p>{`Vorrei evitare fraintendimenti: `}<strong parentName="p">{`un sito web statico può includere animazioni e interagire dinamicamente con i visitatori secondo le tue esigenze`}</strong>{`. Le parole `}<em parentName="p">{`statico`}</em>{` e `}<em parentName="p">{`dinamico`}</em>{` si riferiscono a come un sito web è costruito nelle sue fondamenta; non descrivono le caratteristiche dei suoi contenuti.`}</p>
    <p>{`Vediamo insieme come funzionano i siti web statici.`}</p>
    <h2>{`Inserimento di contenuti`}</h2>
    <p>{`I nostri siti web statici dispongono di un’`}<em parentName="p">{`interfaccia di amministrazione`}</em>{` intuitiva grazie alla quale puoi modificare pagine, caricare file e cambiare impostazioni.`}</p>
    <p>{`Vuoi vedere con i tuoi occhi come funziona questa interfaccia? `}<a parentName="p" {...{
        "href": "contact"
      }}>{`Chiedimi una demo`}</a>{`.`}</p>
    <p>{`I siti web statici possono inoltre raccogliere informazioni da infinite origini alternative come database, servizi web e software di terze parti, come il tuo gestionale.`}</p>
    <p>{`Al momento della pubblicazione, in una fase detta di `}<em parentName="p">{`compilazione`}</em>{`, tutte le informazioni raccolte vengono processate in una serie di file standard e altamente ottimizzati per il server web.`}</p>
    <h2>{`Ottimizzazione delle immagini`}</h2>
    <p>{`Tutte le immagini caricate vengono ottimizzate per migliorare la loro velocità di scaricamento e la loro definizione grafica perché i visitatori possano vederle sempre perfette sia da PC che da smartphone.`}</p>
    <h2>{`Back-end dinamici`}</h2>
    <p>{`I siti web statici possono essere agganciati a `}<em parentName="p">{`back-end`}</em>{` dinamici aprendo le porte a infinite possibilità, come:`}</p>
    <ul>
      <li parentName="ul">{`gestione di utenti con registrazione, accesso e area riservata`}</li>
      <li parentName="ul">{`e-commerce, pagamenti`}</li>
      <li parentName="ul">{`database`}</li>
      <li parentName="ul">{`email, calendari`}</li>
      <li parentName="ul">{`prenotazioni`}</li>
      <li parentName="ul">{`caricamento di informazioni dai social`}</li>
    </ul>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    